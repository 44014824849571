import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import dayjs from 'dayjs';
import * as dayjsNl from 'dayjs/locale/nl'
window.dayjsNl=dayjsNl;
dayjs.locale('nl');

import {BookingWidget, CheckAvailability, LangContext} from './booking-widget/index.jsx';

const theme = createTheme({
    palette: {
        primary: {
            main: '#5a6d15',
        },
        secondary:{
            main:'#081828'
        }
    }
    , components:{
        MuiInputBase:{
            styleOverrides:{
                root:{
                    backgroundColor:'#fff !important'
                }
            }
        }
        , MuiAlert:{
            styleOverrides:{
                message:{
                    fontSize:'16px'
                    , lineHeight:'22px'
                }
            }
        }
    }
});


(function(){
    if( typeof window === 'undefined' ) return;
    // don't init framework twice
    if( typeof window.WebApp === 'function' ) return;

    const WebApp = function (){}
    window.WebApp = WebApp;

    WebApp.render = (target, component, props)=>{
        try{
            const root = createRoot(target);
            root.render(
                React.createElement(LangContext.Provider, {value:props.lang},
                    React.createElement(ThemeProvider, {theme},
                        React.createElement(component, props, null)
                    )
                )
            );
            return root;
        }catch(err){
            console.error(err);
            target.innerHTML = `<div style="background:#fff; padding:10px; color:#f33636">An error occurred when rendering the application, try refreshing the page.<br/>${err.toString()}</div>`
        }
    }

    const int = setInterval(()=>{
        const elem = document.getElementById('check-availability');
        if( elem){
            clearInterval(int);
            WebApp.render(elem, CheckAvailability, {lang: document.documentElement.getAttribute('lang') });
        }
    },100);

    const int2 = setInterval(()=>{
        const elem = document.getElementById('booking-widget');
        if( elem){
            clearInterval(int2);
            WebApp.render(elem, BookingWidget, {lang: document.documentElement.getAttribute('lang') });
        }
    },100);


})();