module.exports = function(language){
    return function lang(...args){
        if( ! args.length)
            return language;

        const [ arg ] = args;
        // Remove leading whitespace
        let string = (arg || '').toString().replace(/^\s+/,'');
        string = string.split(/\s*(?=\[[a-zA-Z][a-zA-Z]\])/g);

        // If it doesn't contain language tags, just return
        if( ! string[0].match(/^\[[a-zA-Z][a-zA-Z]\]/))
            return arg;

        string = Object.fromEntries(string.map(v=>{
            let [ k='' ] = v.match(/\[[a-zA-Z][a-zA-Z]\]/);
            k = k.replace(/\[|\]/g,'');
            v = v.replace(/^\[[a-zA-Z][a-zA-Z]\]\s*/,'');
            return [k.toLowerCase(), v];
        }).reverse())
        const [ fallback ] = Object.values(string);
        const result = string[language];
        return result !== void 0 ? result : fallback
    }
}