import React from 'react';
import clsx from 'clsx';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const PREFIX = 'InputCheckbox';
const classes = {
    root: `${PREFIX}-root`,
    info: `${PREFIX}-info`,
    error: `${PREFIX}-error`,
    checkbox: `${PREFIX}-checkbox`,
    label: `${PREFIX}-label`,
    readOnly: `${PREFIX}-readOnly`,
    readOnlyButton: `${PREFIX}-readOnlyButton`,
    highlight: `${PREFIX}-highlight`,
    list: `${PREFIX}-list`,
}

const rootSx = {
    [`&.${classes.root}`]:{
        /*mb: 1
        , mt:0
        , */minHeight:'42px'
        , justifyContent:'center'

    }
    , [`& .${classes.info}`]:{
        ml: 4
    }
    , [`& .${classes.error}`]:{
        ml: 4
        , marginTop: 0
    }
    , [`&.${classes.list}`]:{
        [`& .${classes.error}`]:{
            ml: 0
            , marginTop: 0
        }
    }
    , [`& .${classes.checkbox}`]:{
        marginTop:'-9px'
        , marginBottom:'-9px'
    }
    , [`& .${classes.label}`]:{
        color:'inherit'
    }
    , [`& .${classes.readOnly}`]:{
        '&.Mui-disabled': {
            color: theme=>`${theme.palette.text.primary}`
        }
    }
    , [`& .${classes.readOnlyButton}`]:{
        '&.Mui-disabled': {
            color: theme=>`${theme.palette.text.secondary}`
        }
    }
    , [`& .${classes.highlight}`]:{
        backgroundColor:'#f7f4db'
        , '&.Mui-disabled':{
            backgroundColor:'#f7f4db'
        }
    }
}

export default React.memo(function InputCheckbox(props){

    const ctx = React.useContext(ListContext);

    const { readOnly, value, highlight, disabled, label, onChange, onBlur, error, required, children, checkboxProps={}, name:_name, ...rest } = props;
    const name = props.name || typeof label === 'string' && label || void 0;

    const handleChange = React.useCallback(evt=>{
        let value = evt.target.checked;
        onChange && onChange(value);
        ctx && ctx.handleChange(evt.target.name);
    }, [ctx,onChange]);

    const handleBlur = React.useCallback(evt=>{
        onBlur && onBlur.apply(null, arguments);
        ctx && ctx.handleBlur.apply(null, arguments);
    }, [ctx,onBlur]);

    const errorMessage = error;

    if(ctx){
        const { readOnly, disabled, value, multiple } = ctx;
        const checked = [].concat(value || []).includes(name);
        let props = {...checkboxProps};
        if(multiple === false && ! checkboxProps.icon)
            props.icon = checked ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />
        return <FormControl>
            <FormControlLabel
                control={
                    <Checkbox {...props}
                              className={clsx(classes.checkbox, props.classes, {[classes.readOnlyButton]: readOnly})}
                              checked={checked}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={name}
                    />
                }
                disabled={readOnly || disabled}
                label={<FormLabel required={false} component="span" className={clsx(classes.label, {[classes.readOnly]: readOnly})}>{label}</FormLabel>}
            />
            {children ? <div className={classes.info}>{children}</div> : null}
        </FormControl>
    }

    return <FormControl
        {...rest}
        sx={{...rootSx, ...rest.sx}}
        error={ !! errorMessage}
        className={clsx(classes.root, props.className)}
    >
        <FormGroup className={clsx({[classes.highlight]: highlight})}>
            <FormControlLabel
                control={
                    <Checkbox {...checkboxProps}
                              className={clsx(classes.checkbox, checkboxProps.classes, {[classes.readOnlyButton]: readOnly})}
                              checked={value || false}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={name}
                    />
                }
                disabled={readOnly || disabled}
                label={<FormLabel required={!!required} component="span"
                                  className={clsx(classes.label, {[classes.readOnly]: readOnly})}>{label}</FormLabel>}
            />
        </FormGroup>
        {errorMessage ? <FormHelperText className={classes.error}>{errorMessage}</FormHelperText> : null}
        {children ? <div className={classes.info}>{children}</div> : null}
    </FormControl>
})

export const ListContext = React.createContext();

export const InputCheckboxList = React.memo(function InputCheckboxList(props){
    const readOnlyContext = React.useContext(ReadOnlyContext);

    const { readOnly=readOnlyContext, value, highlight, disabled, onChange, onBlur, error, required, helperText, label, children, row, multiple=true, ...rest } = props;

    const valueRef = React.useRef(value || []);
    if(valueRef.current !== value)
        valueRef.current = value || [];

    const handleChange = React.useCallback(name=>{
        if( ! multiple){
            onChange && onChange(name);
            return;
        }
        const value = [...valueRef.current];
        if(value.includes(name))
            value.splice(value.indexOf(name),1)
        else
            value.push(name);
        onChange && onChange(value);
    }, [onChange, multiple]);

    const handleBlur = React.useCallback(evt=>{
        onBlur && onBlur.apply(null, arguments);
    }, [onBlur]);

    const errorMessage = error;

    const ctx = React.useMemo(()=>({ readOnly, disabled, value, handleChange, handleBlur, multiple }), [readOnly, disabled, value, handleChange, handleBlur, multiple]);

    return <FormControl
        {...rest}
        sx={{...rootSx, ...rest.sx}}
        error={ !! errorMessage}
        className={clsx(classes.root, classes.list, props.className)}
    >
        { !! label && <FormLabel required={!!required} component="legend">{label}</FormLabel>}
        <FormGroup className={clsx({[classes.highlight]: highlight})}>
            <ListContext.Provider value={ctx}>
                {children}
            </ListContext.Provider>
        </FormGroup>
        { !! helperText && <FormHelperText>{errorMessage}</FormHelperText>}
        { !! errorMessage && <FormHelperText className={classes.error}>{errorMessage}</FormHelperText>}
    </FormControl>
})