import React from 'react';
import clsx from 'clsx';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {filledInputClasses} from "@mui/material/FilledInput";
import {inputLabelClasses} from "@mui/material/InputLabel";
import {nativeSelectClasses} from "@mui/material/NativeSelect";

const PREFIX = 'InputSelect';
const classes = {
    box: `${PREFIX}-box`,
    root: `${PREFIX}-root`,
    placeholder: `${PREFIX}-placeholder`,
    readOnly: `${PREFIX}-readOnly`,
    highlight: `${PREFIX}-highlight`,
    defaultVariant: `${PREFIX}-defaultVariant`,
}

const boxSx = {
    [`&.${classes.box}`]:{
        position: 'relative'
    }
    , [`&.${classes.root}`]:{
        mt: 0
        , mb: 1
    }
    , [`&.${classes.defaultVariant}`]:{
        [`& .${filledInputClasses.root}`]:{
            '&:before':{display:'none'}
            , '&:after':{display:'none'}
            , background:'transparent'
            , border:theme=>'1px solid '+theme.palette.divider
            , borderRadius: '0px'
            , '&:hover':{background:theme=>theme.palette.action.hover}
            , [`&.${filledInputClasses.focused}`]:{
                background:theme=>theme.palette.action.hover
                , borderColor: theme=>theme.palette.primary.main
            }
        }
        , [`& .${nativeSelectClasses.select}`]:{
            '&:focus':{background:'transparent'}
        }
    }
    , [`&.${classes.readOnly}`]:{
        [`& .${filledInputClasses.root}`]:{
            //borderColor:'transparent'
            [`& .${filledInputClasses.disabled}`]:{
                color: theme=>theme.palette.text.primary
                , textFillColor: theme=>theme.palette.text.primary
            }
            , '&:hover':{background:'transparent'}
        }
        , [`& .${inputLabelClasses.root}.${inputLabelClasses.disabled}`]:{
            color: (theme)=>`${theme.palette.text.secondary}`
        }
        , [`& .${nativeSelectClasses.icon}`]:{
            display:'none'
        }
    }
    , [`&.${classes.highlight}`]:{
        [`& .${filledInputClasses.root}, .${filledInputClasses.root}:hover, .${filledInputClasses.root}:active, .${filledInputClasses.root}:focus`]: {
            backgroundColor: '#f7f4db !important'
        }
    }
    , [`& .${classes.placeholder}`]:{
        position: 'absolute'
        , top: 0
        , left: 0
    }
}

export default React.memo(function InputSelect(props) {
    const { readOnly, value, highlight, variant, disabled, sx, helperText, toString, onChange, onBlur, error, SelectProps, label, placeholder, ...textFieldProps } = props;

    const handleChange = React.useCallback( (evt)=>{
        let value = evt.target.value;
        if(toString !== false && value === 'null') value = null;
        if(toString !== false && value === 'true') value = true;
        if(toString !== false && value === 'false') value = false;
        if(toString !== false && isNumeric(value)) value = value-0;
        onChange && onChange(value);
    }, [toString, onChange]);

    const handleBlur = React.useCallback(evt=>{
        onBlur && onBlur.apply(null, arguments);
    }, [onBlur]);

    const errorMessage = error;

    const fieldProps = {
        margin: label ? 'none' : 'dense'
        , variant: variant || 'filled'
        , helperText: errorMessage || helperText || null
        , error: !! errorMessage
        , hiddenLabel: ! label
        , label
        , ...textFieldProps

        , select: true
        , disabled: readOnly || disabled
        , SelectProps: {native:true, ...SelectProps}
        , InputLabelProps:{shrink:true, ...textFieldProps.InputLabelProps}
        , onChange: handleChange
        , onBlur: handleBlur
        , value: value === void 0 ? '' : (value === null ? 'null' : value.toString())
    }

    const placeholderProps = {
        margin: 'margin' in textFieldProps ? textFieldProps.margin : (label ? 'none' : 'dense')
        , size: textFieldProps.size
        , variant: variant || 'filled'
        , placeholder
        , hiddenLabel: ! label
        , inputProps:{tabIndex:'-1'}
        , disabled: readOnly || disabled
        , className: clsx(classes.placeholder)
        , InputProps: textFieldProps.InputProps
    }

    return <Box className={clsx(classes.box, classes.root, {[classes.defaultVariant]: ! variant, [classes.readOnly]: !! readOnly, [classes.highlight]: !! highlight})} sx={{...boxSx, ...sx}}>
        {value === void 0 && ! readOnly ? <TextField {...placeholderProps} key={'placeholder'} /> : null}
        <TextField {...fieldProps} key={'input'} />
    </Box>
})

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}