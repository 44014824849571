module.exports = {
    getMinDate
    , dateAdd
    , toEuropeTz
    , isValidDate
    , getMaxDate
}
const CHECK_IN_LIMIT = '18:00:00';
const MAX_DAYS_LIMIT = 6*30;

function toEuropeTz(date){
    let options = {
            timeZone: 'Europe/Amsterdam',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12:false
        },
        formatter = new Intl.DateTimeFormat('nl-NL', options);
    const dt = new Date();
    dt.setMilliseconds(0);
    const [day, month, year, hour, min, sec] = formatter.format(dt).replace(/,/g,'').split(/\-| |:/g);
    const dtz = new Date(year, month-1, day, hour, min, sec);
    const offset = Math.round((dtz.getTime() - dt.getTime())/1000/60);
    date.setMinutes(date.getMinutes()+offset);
}

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

function europeTime(year, month, day, hour=0, min=0, sec=0){
    let dt = new Date(year, month, day, hour, min, sec);

    // Format to Amsterdam timezone
    let amsterdamTime = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'Europe/Amsterdam'
    }).format(dt);

    // amsterdamTime is in string format, create a Date object
    let dateObject = new Date(amsterdamTime);

    return dateObject;
}

function getMinDate(){
    // Safari doesn't support - date separator and instead it looks for / separator
    const [year, month, day, hour, min, sec] = new Date().toISOString().substr(0,10).split('-').concat(CHECK_IN_LIMIT.split(':'));

    let minDate = europeTime(year, month-1, day, hour, min ,sec)
    if(Date.now() > minDate.getTime()) {
        minDate.setDate(minDate.getDate() + 1)
    }
    return minDate;
}

function getMaxDate(){
    // Safari doesn't support - date separator and instead it looks for / separator
    const [year, month, day] = new Date().toISOString().substr(0,10).split('-');
    const maxDate = europeTime(year, month, day)
    dateAdd(maxDate, MAX_DAYS_LIMIT)
    return maxDate;
}

function dateAdd(d, n){
    if(typeof d === 'string')
        d = new Date(d);
    d.setDate(d.getDate()+n);
    return d;
}