import React from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import InputText from "./input-text";

import dayjs from 'dayjs';

const PREFIX = 'InputDate';
const classes = {
    root: `${PREFIX}-root`,
}

const rootSx = {

}

export default React.memo(function InputDate(props){

    const { value, onChange
        , readOnly, highlight, variant, disabled, error, helperText, label, required, placeholder, InputProps,
        ...rest } = props;

    const refs = React.useRef({});
    {
        const curr = refs.current;
        curr.views || (curr.views = ['year','month','day'])
        curr.inputFormat || (curr.inputFormat = "YYYY-MM-DD")
        curr.placeholder || (curr.placeholder = "YYYY-MM-DD")
        curr.mask || (curr.mask = "____-__-__")
    }

    const handleChange = (d, finalString)=>{
        if( ! d && ! finalString){
            const value = null;
            onChange && onChange(value);
        }else{
            const inputFormat = rest.inputFormat || refs.current.inputFormat
            const date = finalString ? dayjs(finalString || '', inputFormat, true) : d
            const value = date.isValid() ? new Date(Date.UTC.apply(null, date.format('YYYY-MM-DD').split('-').map((v,i)=>i===1?v-1:v).concat([0,0,0]))).toISOString() : false
            onChange && onChange( value );
        }
    }

   const inputValue = value === false ? false : (value ? dayjs(value).format(rest.inputFormat || refs.current.inputFormat) : '')

    if (readOnly || disabled)
        return <InputText {...({readOnly, highlight, variant, disabled, error, helperText, label, required
        , placeholder:placeholder || refs.current.placeholder, value:inputValue})} />

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
            allowSameDateSelection
            openTo='day'
            views={refs.current.views}
            inputFormat={refs.current.inputFormat}
            mask={refs.current.mask}
            desktopModeMediaQuery=""
            {...rest}
            renderInput={(params) =>{
                return <InputText {...({...InputProps, ...params, readOnly, highlight, variant, disabled, error, helperText, label, required
                    , inputProps:{...(InputProps || {}).inputProps, ...params.inputProps, placeholder:placeholder || refs.current.placeholder, value:inputValue || params.inputProps.value}})} />
            } }
            value={value || null}
            onChange={handleChange}
        />
    </LocalizationProvider>
})