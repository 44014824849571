import React from 'react';
import clsx from 'clsx';
import InputText from './input-text';
import Paper from '@mui/material/Paper'
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import InputAdornment from '@mui/material/InputAdornment';

import { inputAdornmentClasses } from '@mui/material/InputAdornment'
import { inputBaseClasses } from '@mui/material/InputBase'
import { autocompleteClasses } from '@mui/material/Autocomplete'

const StyledPaper = (props)=><Paper square {...props} sx={{...props.sx, width:'calc(100% + 2px)', marginLeft:'-1px'}} />

const PREFIX = 'InputAutocomplete';
const classes = {
    root: `${PREFIX}-root`,
    readOnly: `${PREFIX}-readOnly`
}

const rootSx = {
    [`&.${classes.root}`]: {
        [`& .${inputAdornmentClasses.positionStart}`]: {marginTop: '0px !important'}
        , [`& .${inputAdornmentClasses.positionEnd}`]: {marginTop: '-19px'}
        , [`& .${inputBaseClasses.hiddenLabel}`]: {paddingTop: '0px'}
        , [`& .${autocompleteClasses.inputRoot} .${autocompleteClasses.input}`]: {minWidth: '30%'}
    }
    , [`&.${classes.readOnly}`]: {
        [`& .${autocompleteClasses.popupIndicator}`]:{display:'none'}
    }
}

/*
    TODO: -- Checkboxes Input
        Checkboxlist with option to add options, and hide unselected while not focused. "Show unselected options", maybe in a dialog, with search-bar and "add option" at bottom?
 */

export default React.memo(React.forwardRef(function InputAutocomplete(props, ref){
    const { id, onChange, onInputChange, readOnly, loading, options=[], required, placeholder, highlight, variant, disabled, error, helperText, label, InputProps, sx, className, ...rest} = props;

    const value = props.value || (rest.multiple ? [] : null);

    const [ inputValue, setInputValue ] = React.useState('');

    React.useEffect(()=>{
        'inputValue' in rest && setInputValue(rest.inputValue || '')
    }, [rest.inputValue]);

    const inputValueClickRef = React.useRef(null);

    const handleChange = (evt, next, reason)=>{
        // clicking in menu, will first fire click, then blur. The click will clear inputValue through handleInputChange
        const { current: inputValueClick } = inputValueClickRef;
        if( evt.type !== 'blur' ){
            if(rest.multiple) next = next.filter((v,i,a)=>a.indexOf(v) === i)
            onChange && onChange(next, evt)
        }else if(rest.multiple && !! rest.freeSolo && rest.autoSelect !== false && inputValue && inputValue !== inputValueClick){
            // Only add inputValue if tabbing/enter, don't add highlighted (focused) value from menu
            next = value.concat(inputValue).filter((v,i,a)=>a.indexOf(v) === i);
            onChange && onChange(next, evt)
            setInputValue('');
            onInputChange && onInputChange('')
        }
        inputValueClickRef.current = null;
    }

    const handleInputChange = (evt, value, reason)=>{
        if(evt && evt.type === 'click')
            inputValueClickRef.current = inputValue;
        if( (! evt || evt.type !== 'blur' || rest.clearOnBlur === true) && ! (evt && evt.type === 'click' && reason === 'select' && rest.blurOnSelect === false))
            setInputValue(value);
        if(reason === "reset")
            return;

        if(rest.freeSolo && ! rest.multiple){
            onChange && onChange(value)
        }
        onInputChange && onInputChange(value)
    }

    const idRef = React.useRef(id || `${Date.now()}${Math.random()}`)

    return <Autocomplete
        ref={ref}
        autoSelect={ !! rest.freeSolo}
        blurOnSelect
        disableClearable
        PaperComponent={StyledPaper}
        {...rest}
        readOnly={readOnly}
        inputValue={inputValue}
        options={options}
        id={idRef.current}
        value={value}
        onInputChange={handleInputChange}
        sx={{...sx, ...rootSx}}
        className={clsx(className, classes.root, {[classes.readOnly]:readOnly})}
        loading={loading}
        onChange={handleChange}
        disabled={disabled}
        ChipProps={{size:'small', ...rest.ChipProps}}
        ListboxProps={{...rest.ListboxProps}}
        renderInput={(params) =>{
            const {className, onBlur, onChange, onFocus, onMouseDown, ref, spellCheck, value} = params.inputProps
            const setRefs = (r)=>{
                ref.current = r;
                (InputProps || {}).ref && (InputProps.ref.current = r);
            }
            const props = {
                ...params
                , id: idRef.current
                , required
                , highlight
                , disabled
                , error
                , helperText
                , placeholder
                , label
                , inputProps: {className, onBlur, onChange, onFocus, onMouseDown, ref:setRefs, spellCheck, value}
                , InputProps: {
                    ...InputProps,
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <InputAdornment position="end"><CircularProgress color="inherit" size={20} /></InputAdornment> : null}
                            {(InputProps || {}).endAdornment}
                            {(params.InputProps || {}).endAdornment}
                        </React.Fragment>
                    ),
                    startAdornment: (
                        <React.Fragment>
                            {(InputProps || {}).startAdornment}
                            {(params.InputProps || {}).startAdornment}
                        </React.Fragment>
                    ),
                }
            }
            return <InputText {...props} />
        }}
    />
}))