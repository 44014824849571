import 'intl/dist/Intl.js';

import React from 'react';
import InputDate from './input-date';
import InputText from './input-text';
import InputAutocomplete from './input-autocomplete';
import InputCheckbox from './input-checkbox';
import InputSelect from './input-select';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

const MAX_ROOMS = 3;
const MAX_DAYS = 7;

import { getMinDate, dateAdd } from './../libs/time';
import lang from './../libs/lang';

function PrimaryButton(props){
    return <Button  color="secondary" variant="contained" {...props} sx={{fontFamily:'Inter, sans-serif', ['&:hover']:{backgroundColor:'#5a6d15'}}} />
}

export function BookingWidget(){
    const lang = useLang();
    let [initFrom, initTo] = React.useMemo(()=>{
        let initFrom=getMinDate(), initTo=dateAdd(getMinDate(),1);
        initFrom = new Date(Date.UTC.apply(null, initFrom.toISOString().substr(0,10).split('-').map((v,i)=>i===1?v-1:v).concat([0,0,0]))).toISOString();
        initTo = new Date(Date.UTC.apply(null, initTo.toISOString().substr(0,10).split('-').map((v,i)=>i===1?v-1:v).concat([0,0,0]))).toISOString();
        return [initFrom, initTo]
    },[]);
    if((window.location.href.split('#')[1] || '').length && window.location.hash !== '#cancel' && window.location.hash !== '#confirm'){
        const [f='', t=''] = window.location.href.split('#')[1].split('&');
        initFrom = new Date(Date.UTC.apply(null, f.split('=').pop().split('-').map((v,i)=>i===1?v-1:v).concat([0,0,0]))).toISOString();
        initTo = new Date(Date.UTC.apply(null, t.split('=').pop().split('-').map((v,i)=>i===1?v-1:v).concat([0,0,0]))).toISOString();
        history.replaceState('', '', window.location.href.split('#').shift());
        window.location.hash = '';
    }
    const [from, setFrom] = React.useState(initFrom);
    const [to, setTo] = React.useState(initTo);
    const [rooms, setRooms] = React.useState('1');
    const [data, setData] = React.useState(null);
    const [persons, setPersons] = React.useState([]);
    const [reservation, setReservation] = React.useState(null);
    React.useEffect(()=>{
        if(to && to <= from || ! to && from)
            setTo(dateAdd(from, 1).toISOString())
    },[from]);

    const handleBook = ()=>setReservation(({from, to, rooms, persons}));

    if( window.location.hash === '#cancel')
        return <Alert severity="warning">{lang(`[en]Your reservation will be cancelled if not payed within 24 hours. Please check your email for payment details.
        [nl]Uw reservering wordt geannuleerd indien niet binnen 24 uur betaald. Controleer uw e-mail voor betalingsgegevens.
        [de]Ihre Reservierung wird storniert, wenn sie nicht innerhalb von 24 Stunden bezahlt wird. Bitte überprüfen Sie Ihre E-Mail auf Zahlungsdetails.`)}</Alert>;

    if( window.location.hash === '#confirm')
        return <Alert severity="success">{lang(`[en]Your reservation is confirmed and paid. A confirmation has been sent to your email. Thank you!
        [nl]Uw reservering is bevestigd en betaald. Er is een bevestiging naar uw e-mailadres verzonden.
        [de]Ihre Reservierung ist bestätigt und bezahlt. Eine Bestätigung wurde an Ihre E-Mail gesendet.`)}</Alert>;

    if(reservation)
        return <MakeReservation {...reservation} data={data} onBack={()=>setReservation(null)} />

    return <>
        <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{['& > div']:{flexBasis:'100%', marginBottom:theme=>`${theme.spacing(1)} !important`, marginTop:theme=>`0 !important`}}}>
            <InputDate value={from} label={lang('[nl]Aankomstdatum [en]Arrival date [de]Anreise')} onChange={setFrom} minDate={getMinDate()} />
            <InputDate value={to} label={lang('[nl]Vertrekdatum [en]Departure date [de]Abreise')} onChange={setTo} minDate={dateAdd(from || new Date(), 1)} maxDate={dateAdd(from || new Date(), MAX_DAYS)} />
            <InputSelect value={rooms} onChange={setRooms} label={lang('[nl]Kamers [en]Rooms [de]Zimmer')} sx={{['& > div']:{width:'100%'}}}>
                {[...new Array(MAX_ROOMS)].map((v,i)=><option value={i+1}>{i+1}</option>)}
            </InputSelect>
        </Stack>
        <Divider sx={{my:2}} />
        { !! (from && to && rooms) && <Availability key={`${from} ${to}`} from={from} to={to} rooms={rooms} persons={persons} setPersons={setPersons} data={data} setData={setData} />}
        { !! (from && to && rooms) && data && data.available >= Number(rooms) && <Box sx={{display:'flex', justifyContent:'center'}}>
            <PrimaryButton disableElevation size="large" onClick={handleBook}>{lang('[nl]Boek nu [en]Book now [de]Jetzt buchen')}</PrimaryButton>
        </Box> }
    </>;
}

function Availability({from, to, rooms, persons, setPersons, data, setData}){

    const lang = useLang();
    const [error, setError] = React.useState(null);

    React.useEffect(()=>{
        setData(null);
        window.fetch(window.location.origin+`/booking?from=${from.substr(0,10)}&to=${to.substr(0,10)}`)
            .then(rs=>{
                if(rs.status === 200)
                    return rs.json().then(rs=>setData(rs));
                else
                    return rs.text().then(txt=>setError(new Error(`Server responded with status ${rs.status}. ${(txt || '').replace(/^Error: /,'')}`)))
            })
            .catch(err=>{
                setError(err)
            });
    },[]);

    React.useEffect(()=>{
        setPersons(persons.concat([...new Array(MAX_ROOMS)].map((v)=>2)).splice(0, rooms))
    },[rooms]);

    if( error)
        return <Alert severity="error">{lang(error.toString())}</Alert>

    if( ! data)
        return <LinearProgress variant="indeterminate" sx={{mx:'auto', my:3, width:'30%'}} />;

    if( data.available < Number(rooms) )
        return <Alert severity="warning">{lang(`
            [en]Unfortunately there is no room available in the requested period. We are closed on Sundays.
            [nl]Geen van de kamers is beschikbaar voor de geselecteerde periode. We zijn gesloten op zondagen.
            [de]Leider ist kein Zimmer im gewünschten Zeitraum verfügbar. Wir sind jeden Sonntag geschlossen.
        `)}</Alert>;

    return <>
        {persons.map((v,i)=>{
            const totalCost = data.room + v*data.breakfast+data.tax * v;
            return <React.Fragment key={i}>
                <Box sx={{display:'flex', mb:2, ['& > div']:{p:1}}}>
                    <Box sx={{maxWidth:'20%', flexGrow:0, flexShrink:0, ['& > img']:{width:'100%'}}}>
                        <img alt="Standaardkamer" src="/nieuw/images/DSC_0521.jpg"/>
                    </Box>
                    <Box sx={{flexGrow:1, flexShrink:1}}>
                        <span>{lang('[nl]Kamer met ontbijt [en]Room with breakfast [de]Zimmer mit Frühstück')}</span>
                        <div>{lang(`
                            [nl]Onze kamers zijn allemaal voorzien van 2 boxspring bedden (90x200cm), draadloos internet, Flat-screen tv. minibar, airconditioning en koffie en thee faciliteit Elke kamer beschikt over een eigen badkamer.
                            [en]Our rooms are all equipped with 2 boxspring beds (90x200), wireless internet, flatscreen TV, minibar, aircondioning coffee and tea and each room has its own bathroom.
                            [de]Unsere Zimmer sind alle ausgestatten mit 2 Boxspring Betten (90x200), WLAN, ein Flachbildschirm, minibar, Klimaanlage, kaffee und tee facilität und eigenes Badezimmer.
                        `)}
                        </div>
                        {/*<Box component="ul" className="vbulcarats" sx={{display:'flex',mt:0.5, ['& > li']:{m:0, pr:1}}}>
                            <li><span className="vbo-expl" data-vbo-expl="Wi-Fi"><img
                                src="https://hofnarroermond.nl/components/com_vikbooking/resources/uploads/wifi-rounded.png"
                                alt="Wi-Fi" /></span></li>
                            <li><span className="vbo-expl" data-vbo-expl="TV"><img
                                src="https://hofnarroermond.nl/components/com_vikbooking/resources/uploads/tv-rounded.png"
                                alt="TV /" /></span></li>
                            <li><span className="vbo-expl" data-vbo-expl="Minibar in de kamer"><img
                                src="https://hofnarroermond.nl/components/com_vikbooking/resources/uploads/minibar-rounded.png"
                                alt="Minibar" /></span></li>
                            <li><span className="vbo-expl" data-vbo-expl="Badkamer"><img
                                src="https://hofnarroermond.nl/components/com_vikbooking/resources/uploads/wc-rounded.png"
                                alt="Badkamer" /></span></li>
                        </Box>*/}
                    </Box>
                    <Box sx={{flexGrow:0, flexShrink:0, width:'100px'}}>
                        <InputSelect value={v} onChange={v=>setPersons(p=>{
                            const n = [...p];
                            n[i] = Number(v);
                            return n;
                        })} label={lang('[nl]Personen [en]Persons [de]Personen')} sx={{['& > div']:{width:'100%'}}}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </InputSelect>
                        <Box sx={{textAlign:'center', fontWeight:600}}>
                            {toAmount(Number.parseFloat(Math.round((totalCost)*100)/100).toFixed(2))}
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{my:2}} />
            </React.Fragment>
        })}
    </>
}

const RULES_LINK = {
    nl:'/nl/informatie.html'
    , de:'/de/information.html'
    , en:'/en/information.html'
}

function MakeReservation({from, to, rooms, data, persons, onBack}){
    const lang = useLang();
    const { localStorage } = window;
    const { nights } = data;
    const cityTax = data.tax * persons.reduce((s,v)=>s+v,0);
    const [ form, setForm ] = React.useState({...(localStorage && JSON.parse(localStorage.getItem('booking-details') || '{}') || {}), approve:false, repeatEmail:''});
    const roomCosts = persons.map(value=>data.room + value*data.breakfast);
    const totalCost = roomCosts.reduce((s,v)=>s+v,0)+cityTax
    const rulesLink = RULES_LINK[getHtmlLanguage()];
    const [error, setError] = React.useState(null);
    const [submitted, setSubmitted] = React.useState();
    const [ saving, setSaving ] = React.useState();
    const errors = React.useMemo(()=>{
        const errors = {};
        if( ! (form.firstName || '').replace(/[^a-zA-Z \-]/g,'').trim())
            errors.firstName = '[en]Field is required [nl]Veld is verplicht [de]Feld ist erforderlich'
        if( ! (form.lastName || '').replace(/[^a-zA-Z \-]/g,'').trim())
            errors.lastName = '[en]Field is required [nl]Veld is verplicht [de]Feld ist erforderlich'
        if( ! (form.email || '').match(/^[^@]+@[^!@#$%^&*();:,?\/\\=+<>]+\.[^!@#$%^&*();:,?\/\\=+<>]+$/))
            errors.email = lang('[en]Type a valid e-mail address [nl]Typ een geldig e-mailadres [de]Geben Sie eine gültige E-Mail-Adresse ein')
        if( form.email !== form.repeatEmail)
            errors.repeatEmail = lang('[en]E-mail address does not match [nl]E-mail adres komt niet overeen [de]E-Mail Adresse stimmt nicht überein')
        if( ! (form.phone || '').match(/^\+[0-9]{5}[0-9]+$/))
            errors.phone = lang('[en]Type a valid phone number [nl]Typ een geldig telefoonnummer [de]Geben Sie eine gültige Telefonnummer ein')
        if( ! form.approve)
            errors.approve = '[en]Field is required [nl]Veld is verplicht [de]Feld ist erforderlich'
        if( ! (form.birthYear || '').match(/^[0-9]{4}$/))
            errors.birthYear = '[en]Field is required [nl]Veld is verplicht [de]Feld ist erforderlich'
        return errors;
    },[form]);

    React.useEffect(()=>{
        if(error === '[en]Form contains errors [nl]Formulier bevat fouten [de]Formular enthält Fehler' && ! Object.keys(errors).length)
            setError(null);
    },[ !! Object.keys(errors).length])

    React.useEffect(()=>{
        if(!submitted) return;
        setError(null);
        if(Object.keys(errors).length){
            setError('[en]Form contains errors [nl]Formulier bevat fouten [de]Formular enthält Fehler')
            return;
        }
        setSaving(true);
        if(localStorage)
            localStorage.setItem('booking-details', JSON.stringify(form));
        window.fetch(window.location.origin+`/booking`, {
            method:'POST'
            , "Content-Type": "application/json"
            , body: JSON.stringify({
                ...form
                , from: from.substr(0,10)
                , to: to.substr(0,10)
                , rooms: persons
                , redirect: window.location.origin + window.location.pathname
                , language: getHtmlLanguage()
            })
        })
            .then(rs=>{
                if(rs.status === 200)
                    rs.json().then(rs=>{ window.location.href = rs.redirect })
                else
                    throw new Error(`Server responded with status ${rs.status}.`);
            })
            .catch(err=>{
                setSaving(false);
                setError(err)
            });
    },[submitted]);
    return <>
        <Box sx={{display:'flex', pb:3, ['& > div']:{width:'50%', fontWeight:'500', textAlign:'center'}, ['& strong']:{fontWeight:'600'}}}>
            <div>{lang('[nl]Aankomst [en]Arrival [de]Ankunft')}<br/><strong>{from.substr(0, 10)} 14:00</strong></div>
            <div>{lang('[nl]Vertrek [en]Departure [de]Abfahrt')}<br/><strong>{to.substr(0, 10)} 10:30</strong></div>
        </Box>
        <Box sx={{mb:3, ['& strong']:{fontWeight:'500'}, ['& .total']:{fontWeight:'600'}, ['& .tblrow']:{fontSize:{xs:'0.9em', sm:'1em'}}}}>
            <table className="table vbtableorder">
                <tbody>
                <tr className="tblrow">
                    <td>&nbsp;</td>
                    <td align="center" className="d-none d-sm-table-cell">{lang('[nl]Nachten [en]Nights [de]Nächte')}</td>
                    <td align="center" className="d-none d-sm-table-cell">{lang('[nl]Personen [en]Persons [de]Personen')}</td>
                    <td align="right">{lang('[nl]Totaal prijs [en]Total price [de]Gesamtpreis')}</td>
                </tr>
                {persons.map((value,i)=>{
                    return <tr className="tblrow">
                        <td align="left">
                            <strong>
                            {lang('[nl]Kamer met ontbijt [en]Room with breakfast [de]Zimmer mit Frühstück')}
                            </strong>
                            <div className="d-xs-block d-sm-none">
                                {value} {lang(value == 1 ? '[nl]person [en]person [de]Person' : '[nl]personen [en]persons [de]Personen')} - {nights} {lang(nights == 1 ? '[nl]nacht [en]night [de]Nächt' : '[nl]nachten [en]nights [de]Nächte')}
                            </div>
                        </td>
                        <td align="center" className="d-none d-sm-table-cell">{nights}</td>
                        <td align="center" className="d-none d-sm-table-cell">{value}</td>
                        <td align="right">{toAmount(Number.parseFloat(roomCosts[i]).toFixed(2))}</td>
                    </tr>
                })}
                <tr className="tblrow">
                    <td>
                        {lang('[nl]Toeristenbelasting [en]City tax [de]Ort tax')}
                    </td>
                    <td align="center" className="d-none d-sm-table-cell">&nbsp;</td>
                    <td align="center" className="d-none d-sm-table-cell">&nbsp;</td>
                    <td align="right">{toAmount(Number.parseFloat(cityTax).toFixed(2))}</td>
                </tr>
                <tr className="total">
                    <td>
                        {lang('[nl]Totaal [en]Total [de]Gesamt')}
                    </td>
                    <td align="center" className="d-none d-sm-table-cell">&nbsp;</td>
                    <td align="center" className="d-none d-sm-table-cell">&nbsp;</td>
                    <td align="right">{toAmount(Number.parseFloat(totalCost).toFixed(2))}</td>
                </tr>
                </tbody>
            </table>
        </Box>
        {error && <Alert sx={{mb:1}} severity="error">{lang(error.toString())}</Alert>}
        {saving && <LinearProgress variant="indeterminate" sx={{mb:1}} />}
        <Box sx={saving?{pointerEvents:'none', opacity:0.5}:{}}>
            <Stack direction="row" spacing={1} sx={{['& > div']:{width:'100%'}}}>
                <InputText error={submitted && lang(errors.firstName)} value={form.firstName} required label={lang('[en]First name [nl]Naam [de]Vorname')} onChange={v=>setForm(p=>({...p, firstName:capitalizeFirstLetter((v || '').trimStart())}))} />
                <InputText error={submitted && lang(errors.lastName)} value={form.lastName} required label={lang('[en]Last name [nl]Achternaam [de]Nachname')} onChange={v=>setForm(p=>({...p, lastName:capitalizeFirstLetter((v || '').trimStart())}))} />
            </Stack>
            <Stack direction="row" spacing={1} sx={{['& > div']:{width:'100%'}}}>
                <InputText error={submitted && lang(errors.email)} value={form.email} required label={'E-mail'} onChange={v=>setForm(p=>({...p, email:(v || '').trim()}))} />
                <InputText error={submitted && lang(errors.repeatEmail)} value={form.repeatEmail} required label={lang('[nl]Herhalen e-mail [en]Repeat e-mail [de]Wiederholen e-mail')} onChange={v=>setForm(p=>({...p, repeatEmail:(v || '').trim()}))} />
            </Stack>
            <Stack direction="row" spacing={1} sx={{['& > div']:{width:'100%'}}}>
                <InputText error={submitted && lang(errors.phone)} placeholder={'+316999999'} value={form.phone} required label={lang('[en]Phone [nl]Telefoonnummer [de]Telefon')} onChange={v=>setForm(p=>({...p, phone:'+'+(v || '').replace(/[^0-9]+/g,'')}))} />
                <InputAutocomplete type={"number"} freeSolo options={[...new Array(120)].map((v,i)=>(new Date().getFullYear()-i).toString())} error={submitted && lang(errors.birthYear)} value={form.birthYear} required label={lang('[en]Birth year [nl]Geboortejaar [de]Geburtsjahr')} onChange={v=>setForm(p=>({...p, birthYear:(v || '').replace(/[^0-9]+/g,'').substr(0,4)}))} />
            </Stack>
            <Stack direction="row" spacing={1} sx={{['& > div']:{width:'100%'}}}>
                <InputText value={form.street} label={lang('[en]Street and number [nl]Straat en huisnummer [de]Straße und Hausnummer')} onChange={v=>setForm(p=>({...p, street:(v || '').trimStart()}))} />
                <InputText value={form.city} label={lang('[en]City [nl]Stad [de]Stadt')} onChange={v=>setForm(p=>({...p, city:capitalizeFirstLetter((v || '').trimStart())}))} />
            </Stack>
            <Stack direction="row" spacing={1} sx={{['& > div']:{width:'100%'}}}>
                <InputText value={form.zipCode} label={lang('[en]Postal code [nl]Postcode [de]PLZ')} onChange={v=>setForm(p=>({...p, zipCode:(v || '').trimStart()}))} />
                <InputAutocomplete freeSolo options={['Nederland','Deutschland','België','France']} value={form.country} label={lang('[en]Country [nl]Land [de]Staat')} onChange={v=>setForm(p=>({...p, country:capitalizeFirstLetter((v || '').trimStart())}))} />
            </Stack>
            <Box sx={{width:'60%', ['& > div']:{width:'100%'}, ['& textarea']:{minHeight:'50px'}}}>
                <InputText multiline value={form.remarks} label={lang('[en]Special Requests [nl]Opmerkingen / Speciale wensen [de]Spezialwünsche')} onChange={v=>setForm(p=>({...p, remarks:v}))} />
            </Box>
            <InputCheckbox error={submitted && lang(errors.approve)} required label={<>{lang("[en]I agree to the [nl]Ik ga akkoord met de [de]Ich akzeptiere die")} <a target="_blank" href={rulesLink}>{lang("[en]terms and conditions [nl]algemene voorwaarden [de]Allgemeinen Geschäftsbedingungen")}</a></>} value={form.approve} onChange={v=>setForm(p=>({...p, approve:v}))} />

            <Box sx={{display:'flex', justifyContent:'space-between', mt:1}}>
                <PrimaryButton variant="text" onClick={onBack}>{lang('[en]Back [nl]Terug [de]Zurück')}</PrimaryButton>
                <PrimaryButton disabled={!!saving} variant="contained" disableElevation size="large" onClick={()=>setSubmitted(Date.now())}>{lang('[nl]Betaal en bevestig [en]Pay and confirm [de]Bezahlen und bestätigen')}</PrimaryButton>
            </Box>
        </Box>
    </>
}

const BOOK_NOW_PAGE = {
    en:'reservation'
    , nl:'reserveren'
    , de:'reservieren'
}
export function CheckAvailability(){
    const [from, setFrom] = React.useState(getMinDate().toISOString());
    const [to, setTo] = React.useState(dateAdd(getMinDate(),1).toISOString());
    const lang = useLang();
    React.useEffect(()=>{
        if(to && to <= from || ! to && from)
            setTo(dateAdd(from, 1).toISOString())
    },[from]);
    const handleClick = ()=>{
        const ln = getHtmlLanguage();
        window.location.href = window.location.origin +window.location.pathname.replace(/\/$/,'')+'/'+BOOK_NOW_PAGE[ln]+`#from=${from.substr(0,10)}&to=${to.substr(0,10)}`;
    }
    return <>
        <InputDate value={from} label={lang('[nl]Aankomstdatum [en]Arrival date [de]Anreise')} onChange={setFrom} minDate={getMinDate()} />
        <InputDate value={to} label={lang('[nl]Vertrekdatum [en]Departure date [de]Abreise')} onChange={setTo} minDate={dateAdd(from || new Date(), 1)} maxDate={dateAdd(from || new Date(), MAX_DAYS)} />
        <PrimaryButton disabled={ ! from || ! to} variant="contained" disableElevation size="large" onClick={handleClick}>{lang('[nl]Controleer Beschikbaarheid [en]Check availability [de]Suche')}</PrimaryButton>
    </>;
}

export const LangContext = React.createContext();

function useLang(){
    const ctx = React.useContext(LangContext);
    return lang(ctx);
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getHtmlLanguage(){
    return document.documentElement.getAttribute('lang')
}

function toAmount(v){
    return '€\xA0' + (v || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\xA0')
}