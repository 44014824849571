import React from 'react';
import clsx from 'clsx';
import TextField from '@mui/material/TextField'

import {filledInputClasses} from '@mui/material/FilledInput';
import {inputLabelClasses} from "@mui/material/InputLabel";

import { inputClasses } from '@mui/material/Input'
import { inputBaseClasses } from '@mui/material/InputBase'
import { formControlClasses } from '@mui/material/FormControl'

/**
 * Extend MaterialUI TextField component
 *
 * @param {string} value
 * @return {string|number}
 *
 * @param {Boolean} multiline - to create a textarea
 */

const PREFIX = 'InputText';
const classes = {
    textarea: `${PREFIX}-textarea`,
    root: `${PREFIX}-root`,
    readOnly: `${PREFIX}-readOnly`,
    highlight: `${PREFIX}-highlight`,
    defaultVariant: `${PREFIX}-defaultVariant`,
}

const textFieldSx = {
    mt: 0
    , mb: 1
    , [`&.${formControlClasses.marginDense}`]:{ m:0, p:0 }
    , [`& .${inputBaseClasses.root}`]:{
        width:'100%'
    }
    , [`&.${classes.defaultVariant}`]:{
        [`& .${filledInputClasses.root}`]:{
            '&:before':{display:'none'}
            , '&:after':{display:'none'}
            , background:'transparent'
            , border:theme=>'1px solid '+theme.palette.divider
            , borderRadius: '0px'
            , '&:hover':{background:theme=>theme.palette.action.hover}
            , [`&.${filledInputClasses.focused}`]:{
                background:theme=>theme.palette.action.hover
                , borderColor: theme=>theme.palette.primary.main
            }
        }
    }
    , [`&.${classes.readOnly}`]:{
        [`& .${filledInputClasses.root}`]:{
            //borderColor:'transparent'
            [`& .${filledInputClasses.disabled}`]:{
                color: theme=>theme.palette.text.primary
                , textFillColor: theme=>theme.palette.text.primary
            }
            , '&:hover':{background:'transparent'}
        }
        , [`& .${inputLabelClasses.root}.${inputLabelClasses.disabled}`]:{
            color: (theme)=>`${theme.palette.text.secondary}`
        }
    }
    , [`&.${classes.highlight}`]:{
        [`& .${filledInputClasses.root}, .${filledInputClasses.root}:hover, .${filledInputClasses.root}:active, .${filledInputClasses.root}:focus`]: {
            backgroundColor: '#f7f4db !important'
        }
    }
}

export default React.memo(React.forwardRef(function InputText(props,ref){
    const { id, readOnly, value, highlight, variant, disabled, sx, onChange, error, selectionIndex, helperText, label, ...textFieldProps } = props;

    const handleChange = React.useCallback( (evt)=>{
        let value = evt.target.value;
        onChange && onChange(value);
    }, [onChange]);

    // Used in Textarea with Autocomplete to restore the selection index after selection an option
    let textAreaRef = (((textFieldProps || {}).inputProps || {}).ref || {}).current;
    let textAreaValue = textAreaRef ? textAreaRef.value : value;
    React.useEffect(()=>{
        if(!textAreaRef || selectionIndex === void 0 || value === textAreaValue)
            return;
        let ref = setTimeout(()=>{
            textAreaRef.setSelectionRange(selectionIndex, selectionIndex);
        },0);
        return ()=>{
            clearTimeout(ref);
        }
    }, [selectionIndex, value, textAreaRef, textAreaValue]);

    const errorMessage = error;
    const idRef = React.useRef(id || `${Date.now()}${Math.random()}`)

    const fieldProps = {
        margin: label ? 'none' : 'dense'
        , variant: variant || 'filled'
        , helperText: errorMessage || helperText || null
        , error: !! errorMessage
        , hiddenLabel: ! label
        , label
        , ...textFieldProps

        , disabled: readOnly || disabled
        , sx: {...textFieldSx, ...sx}
        , className: clsx(classes.root, textFieldProps.className, {[classes.defaultVariant]: ! variant, [classes.highlight]: !! highlight, [classes.readOnly]: !! readOnly})
        , inputProps: {autoComplete:"none", id:idRef.current, ...textFieldProps.inputProps}
        , InputLabelProps: {shrink:true, ...textFieldProps.InputLabelProps}
        , onChange: handleChange
        , value: (value || '').toString()
    }

    return <TextField {...fieldProps} ref={ref} />

}))